.badgeCount {
  &.zero {
    :global {
      .ant-badge-count {
        background-color: #ffffff;
        border: 1px solid #28735c;
        color: #28735c;
      }
    }
  }
  &.color_red {
    :global {
      .ant-badge-count {
        background: #F22013;
      }
    }
  }
  &.color_blue {
    :global {
      .ant-badge-count {
        background: #3C59F8;
      }
    }
  }
  :global {
    .ant-badge {
      margin-bottom: 0;
    }
    .ant-badge-count {
      background: #28735c;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.075px;
      color: #ffffff;
    }
    .ant-scroll-number-only > p.ant-scroll-number-only-unit {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
