.modalWrapper {
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      border-radius: 16px;
    }
    .ant-modal-header {
      border-radius: 16px 16px 0 0;
    }
  }
}
.modalContent {
  padding: 24px;
}
.footer {
  padding: 16px 24px;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0;
  }
}
.announcements {
  max-height: 385px;
  overflow-y: auto;
  overflow-x: hidden;
  .announcement:not(:last-child) {
    margin-bottom: 16px;
  }
}
.announcement {
  display: flex;
  align-items: flex-start;
  .checkbox {
    position: relative;
    top: 4px;
    margin-right: 20px;
  }
  .announcementContent {
    display: flex;
    flex-direction: column;
    color: #1e202b;
    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }
}
.info {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  :global {
    .material-icons {
      width: 20px;
      margin-right: 18px;
      font-size: 20px;
      height: 20px;
    }
  }
}
.loading {
  padding-top: 50px;
}
