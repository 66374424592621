.wrapper {
  display: flex;
  max-width: 200px;
  flex-direction: column;
  align-items: flex-end;
  > span {
    font-size: 13px;
    line-height: 16px;
    display: block;
    margin-bottom: 8px;
  }
  :global {
    .ant-progress {
      margin: 0;
      max-width: 170px;
    }
    .ant-progress-inner,
    .ant-progress-bg {
      height: 16px !important;
    }
    .ant-progress-bg {
      background-color: #3c59f8 !important;
    }
    .ant-progress-inner {
      background-color: #eceefe;
    }
  }
}
@media screen and (max-width: 482px) {
  .wrapper {
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 100%;
    > span {
      margin-bottom: 0;
      min-width: 195px;
      margin-right: 10px;
    }
  }
}
