@import '../../../styles/variables.less';
@import '../../../styles/global/variables.less';

.modalContent {
  height: 75vh;
  overflow: auto;
  line-height: 22px;
}
.footer {
  width: calc(100% + 3rem);
  padding: 1rem 1.5rem 0 1.5rem;
  margin: 0 -1.5rem;
  border-top: 1px solid @grey-5;
  justify-content: space-between;
  @media (max-width: 468px) {
    flex-direction: column;
  }
  .text {
    width: 50%;
    font-weight: 500;
    @media (max-width: 468px) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  &.privacyFooter {
    justify-content: flex-end;
  }
  .privacy {
    color: @link-color;
    cursor: pointer;
  }
  button {
    margin: 0;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.tncWrapper {
  overflow: hidden;
}
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .people {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @media (max-width: 768px) {
      object-fit: none;
    }
    @media (max-width: 1024px) and (min-width: 769px) {
      object-fit: none;
      transform: scale(1.4);
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: @primary-color;
    opacity: 0.5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .header {
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
  }
}
