.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  z-index: 2;
  :global {
    .loader {
      height: auto;
      justify-content: flex-start;
      padding-top: 50px;
    }
  }
  &.isRow {
    z-index: 3;
    :global {
      .loader {
        padding-top: 0;
      }
    }
  }
  &.center {
    :global {
      .loader {
        justify-content: center;
      }
    }
  }
}
