.loginContainer {
  overflow: hidden;
  min-height: 100vh;
  :global {
    .gx-login-content {
      padding: 24px 30px;
    }
  }
}
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  .people {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @media (max-width: 768px) {
      object-fit: none;
    }
    @media (max-width: 1024px) and (min-width: 769px) {
      object-fit: none;
      transform: scale(1.4);
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: #2e79a3;
    opacity: 0.5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .header {
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
  }
  .form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    .title {
      color: #262626;
      margin-bottom: 20px;
    }
    .input {
      width: 100%;
    }
    .icon {
      color: #9f9f9f;
    }
    .submitBtn {
      margin-top: 20px;
    }
  }
}
.buttons {
  button {
    min-height: 40px;
  }
}
