:global {
  .ant-btn-background-ghost {
    &.ant-btn-primary {
      &[disabled] {
        background: transparent !important;
        color: #243699 !important;
        &:hover {
          background: transparent !important;
          color: #243699 !important;
        }
      }
      &:hover {
        background: transparent !important;
        color: #243699 !important;
      }
    }
  }
}
.wrapper {
  &.noMargin {
    &:global(.ant-btn) {
      margin: 0;
    }
  }
  &.weight_md {
    font-weight: 600;
  }
  &.flex {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &.fontSize_md {
    font-size: 14px;
  }
}
