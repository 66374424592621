::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.mainApp {
  width: calc(100% - 96px) !important;
  @media (max-width: 468px) {
    width: calc(100% - 48px) !important;
    margin-top: 1rem !important;
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  &.mainAppFlex {
    display: flex;
    flex-direction: column;
  }
  &.mainAppRelative {
    position: relative;
  }
  &.isDashboardPage {
    @media (max-width: 768px) {
      width: calc(100% - 2rem) !important;
      margin-top: 1rem !important;
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
}
.addMargin {
  margin-bottom: 70px;
}
.mainLayout {
  flex: 1;
  &.whiteBg {
    background: white !important;
  }
  @media (max-width: 991px) {
    padding-top: 80px;
  }
  &:global(.layout-has-timer-button) {
    @media (max-width: 991px) {
      padding-top: 130px;
      > header {
        & + main {
          padding-top: 0 !important;
        }
      }
    }
  }
  &:global(.layout-has-timer-button-running) {
    @media (max-width: 991px) {
      padding-top: 210px;
    }
  }
}
:global {
  #main-wrapper.wrapper-full-screen {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    z-index: 10;
    .main-inner-wrapper {
      margin: 20px 24px !important;
      width: calc(100% - 48px) !important;
      min-width: calc(100% - 48px) !important;
      max-width: calc(100% - 48px) !important;
    }
    .ant-layout-footer {
      display: none;
    }
  }
}
.layoutWrapper {
  &.isParticipantTrials {
    :global {
      #main-wrapper {
        .main-inner-wrapper {
          margin: 0 !important;
          width: 100% !important;
          min-width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .mainLayout {
    height: 100% !important;
  }
  .layoutContent {
    height: 100%;
  }
  .layoutWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    min-height: 100%;
    &.isPIPRunTrial {
      .layoutContent {
        :global {
          .main-inner-wrapper {
            margin: 16px 24px !important;
            width: calc(100% - 48px) !important;
          }
        }
      }
      :global {
        .ant-layout-footer {
          display: none !important;
        }
      }
    }
  }
}

@media print {
  .mainApp {
    width: 100% !important;
  }
}
