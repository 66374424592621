@import '../../styles/variables.less';

.modalWrapper {
  :global .ant-modal-footer {
    padding: 12px 24px;
  }
}

.modalSubTitle {
  width: 100%;
  text-align: center;
  font-weight: @font-weight-bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  color: @text-color;
}
:global {
  .ant-modal {
    padding-bottom: 0 !important;
  }
}
