.notificationsContainer {
  position: fixed;
  right: -1000px;
  z-index: 9999999;
  top: 10px;
  transition: right 0.5s;
  width: 500px !important;
  :global {
    .ant-alert {
      min-width: 500px !important;
    }
    .ant-alert-message {
      font-weight: 500;
    }
  }
  @media (max-width: 528px) {
    width: calc(100% - 30px) !important;
    :global {
      .ant-alert {
        min-width: 100% !important;
      }
    }
  }
  &.show {
    top: 10px;
    right: 50px;
    transition: right 0.5s;
    @media (max-width: 572px) {
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
