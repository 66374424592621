.timerButton {
  order: 4;
  width: 100%;
  position: relative;
  justify-content: center;
  margin-top: 10px;
}
.hide {
  display: none !important;
}
.hideButton {
  > button {
    display: none !important;
  }
}
.naUnderLayer {
  z-index: -1;
}
.notificationBox {
  :global {
    .anticon {
      color: #8c8c8c;
      font-size: 22px;
    }
    .ant-scroll-number.ant-badge-count {
      box-shadow: none;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.69));
      background: linear-gradient(288.09deg, #dd2a2a -2.38%, #ff6969 100%);
      line-height: 20px;
    }
  }
}
.notificationPopover {
  padding-top: 0 !important;
  :global {
    .ant-popover-arrow,
    .icon-charvlet-down {
      display: none;
    }
    .gx-popover-scroll {
      width: 450px !important;
      height: auto !important;
      max-width: 100%;
    }
    .ant-popover-inner {
      border-radius: 16px;
      border: 1px solid #bdbdbd;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
    }
    .ant-popover-inner-content {
      padding: 16px 0 16px 16px;
      border-radius: 16px;
    }
    .gx-sub-popover {
      li {
        padding: 16px 0;
      }
    }
    .gx-popover-header {
      margin-right: 0;
    }
  }
}
.payrollSubmitting {
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  background: #eceefe;
  padding: 8px;
  font-size: 14px !important;
  margin-right: 16px !important;
  .submittingRecords {
    display: flex;
    gap: 4px;
    > span {
      font-weight: 600;
    }
  }
  > div {
    margin: 0 !important;
  }
}
.timerIcon {
  // font-size: 32px;
  position: absolute;
  left: 68px;
  display: flex;
  z-index: 2;
  // color: #00bc5b;
  svg {
    width: 32px;
    height: 32px;
  }
  &.isRunning {
    // svg {
    //   animation-name: rotate;
    //   animation-duration: 20s;
    //   animation-iteration-count: infinite;
    //   animation-timing-function: linear;
    // }
    svg {
      path {
        fill: #f22013;
      }
    }
  }
  &:not(.isRunning) {
    svg {
      path {
        fill: #00bc5b;
      }
    }
  }
}
@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 991px) {
  .timerButton {
    align-self: flex-end;
    margin-bottom: 8px;
    &.timerButtonRuning {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 36px;
      z-index: 1;
      width: 150px;
    }
  }
  .logoWrapper {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 122px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 575px) {
  .notificationPopover {
    right: 0 !important;
  }
}
