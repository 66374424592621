.modalWrapper {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}
.formInner {
  padding: 20px 20px 30px 20px;
  > div {
    display: flex;
    justify-content: space-between;
    &:first-child {
      align-items: center;
      margin-bottom: 15px;
    }
    &:last-child {
      flex-wrap: wrap;
      > div {
        flex: 1;
        min-width: calc(50% - 15px);
        max-width: calc(50% - 15px);
        &:first-child {
          min-width: 100%;
          max-width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
  :global {
    .ant-select {
      width: 100%;
    }
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector {
      color: #545454;
      background: transparent;
      border-color: #d9d9d9;
    }
  }
}
.footerButtons {
  border: 1px solid #d9d9d9;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :global {
    .ant-btn {
      margin: 0;
    }
  }
}
.cancelBtn {
  background-color: transparent !important;
  border-color: #f34d4d !important;
  color: #f34d4d !important;
  &:hover,
  &:focus,
  &:not([disabled]):not(.disabled):active {
    background-color: transparent !important;
    border-color: #f34d4d !important;
    color: #f34d4d !important;
  }
}
.isError {
  :global {
    .ant-select-selector {
      border-color: #f34d4d !important;
    }
  }
}
.locationSelectWrapper {
  &.hasLocationIcon {
    position: relative;
    .locationIcon {
      position: absolute;
      left: 8px;
      top: 30px;
    }
    :global {
      .ant-select-single .ant-select-selector .ant-select-selection-item {
        padding-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .formInner {
    padding: 20px 30px 80px 30px;
    > div {
      &:first-child {
        flex-wrap: wrap;
        margin-bottom: 20px;
        > * {
          min-width: 100%;
          display: flex;
          justify-content: center;
        }
        > span {
          margin-top: 15px;
          font-size: 18px;
          color: #161616;
        }
      }
      &:last-child {
        flex-wrap: wrap;
        > div {
          flex: 1;
          min-width: 100%;
        }
      }
    }
  }
  .timer {
    font-size: 40px;
    line-height: 60px;
    color: #30303080;
  }
  .footerButtons {
    padding: 20px 30px;
  }
}
