.nightAssistBtn {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 16px 0 13px;
  :global {
    .material-icons {
      color: #c5cdfd;
      margin-right: 5px;
    }
  }
}
