.wrapper {
  > span {
    color: #595b64;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    :global {
      .anticon {
        color: #86878d;
        font-size: 20px;
        margin-right: 18px;
      }
    }
  }
}
@media screen and (max-width: 482px) {
  .wrapper {
    > span {
      font-size: 12px;
    }
  }
}
