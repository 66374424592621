.hide {
  display: none !important;
}
.wrapper {
  margin-left: auto;
  margin-right: 28px;
}
.naButtonMobile {
  width: calc(100% - 100px);
  margin-bottom: 0;
  position: relative;
  top: -10px;
}
.naBlockMobile {
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  height: 72px;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.16);
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
  padding: 16px 24px;
  z-index: 1;
  :global {
    .ant-btn {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &.atDashboard {
    bottom: 64px;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    height: 50px;
    background: white;
  }
}
