.wrapper {
  display: flex;
  align-items: center;
  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 15px;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1e202b;
  }
}
