.footerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border: 1px solid #d9d9d9;
  :global {
    .ant-btn {
      margin: 0;
    }
  }
}
.modalWrapper {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
  &.isOver25m {
    .footerButtons {
      border: 0;
    }
    .contentInner {
      > p {
        text-align: center;
      }
    }
    .timer {
      color: #f6511d;
    }
  }
  &.isOver30m {
    .footerButtons {
      border: 0;
      justify-content: center;
      padding-top: 0;
    }
    .contentInner {
      > p {
        text-align: center;
      }
    }
    .warningIcon {
      :global {
        .material-icons {
          color: #f22013;
        }
      }
    }
  }
}
.contentInner {
  padding: 24px;
  .timer {
    margin: 0 auto;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #1e202b;
    width: 180px;
    text-align: center;
  }
  > p {
    font-size: 14px;
    line-height: 24px;
    margin: 24px 0 0 0;
  }
}
.participantDropdown,
.descriptionInput {
  label {
    color: #1e202b;
    display: inline-block;
    margin-bottom: 8px;
  }
}
.participantDropdown {
  :global {
    .ant-select {
      width: 100%;
    }
  }
}
.descriptionInput {
  textarea {
    min-height: 133px;
    max-height: 133px;
    resize: none;
  }
}
.warningIcon {
  text-align: center;
  margin-bottom: 20px;
  :global {
    .material-icons {
      font-size: 51px;
      color: #3c59f8;
    }
  }
}

@media screen and (max-width: 991px) {
  .modalWrapper {
    &.bottomModal {
      :global {
        .ant-modal {
          width: 100% !important;
          max-width: 100%;
          bottom: 0;
          top: auto;
          vertical-align: bottom !important;
          margin: 0 !important;
          .ant-modal-content {
            border-radius: 16px 16px 0 0;
            .ant-modal-header {
              border-radius: 16px 16px 0 0;
            }
          }
        }
      }
      .footerButtons {
        border-right: 0;
        border-bottom: 0;
        border-left: 0;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .footerButtons {
    justify-content: space-between;
    :global {
      .ant-btn {
        margin: 0;
      }
    }
  }
}
