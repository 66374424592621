.wrapper {
  background: #f8f8f8;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  padding: 0 16px;
  max-height: 370px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 18px;
  > span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #86878d;
    display: block;
    width: 100%;
    position: sticky;
    top: 0;
    background: #f8f8f8;
    z-index: 5;
    padding: 16px 0 8px 0;
  }
  > div {
    border-bottom: 1px solid #e1e1e1;
    &:last-child {
      border-bottom: 0;
    }
  }
}
@media screen and (max-width: 482px) {
  .wrapper {
    max-height: 330px;
    > span {
      font-size: 10px;
    }
  }
}
