.wrapper {
  display: flex;
  padding: 16px 3px;
}
.checkbox {
  margin-right: 18px;
  :global {
    .ant-checkbox {
      top: 1px;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  .contentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    h5 {
      color: #1e202b;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin: 0;
    }
    span {
      font-size: 12px;
      line-height: 16px;
      color: #86878d;
    }
    & + div {
      color: #1e202b;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
@media screen and (max-width: 482px) {
  .content {
    .contentHeader {
      flex-direction: column;
      align-items: flex-start;
      h5 {
        margin-bottom: 4px;
      }
    }
  }
}
