.fieldTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #545454;
  margin-bottom: 3px;
}
.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  color: #f5222d !important;
  font-size: 14px;
  line-height: 1.3;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.is-invalid {
  border: #f5222d 1px solid !important;
  :global {
    .material-icons {
      color: #f5222d !important;
    }
  }
}

.inputNumber {
  &:hover {
    :global {
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
}
.required {
  color: #f5222d;
  margin-left: 5px;
}

:global {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-input-lg {
    padding-top: 10.6px;
    padding-bottom: 6.6px;
  }
}
.bgLightGray {
  :global {
    .ant-input,
    .ant-input-affix-wrapper {
      background: #f8f8f8;
      color: #1e202b;
    }
  }
}
.wrapper {
  &.text_size_md {
    :global {
      .ant-input,
      .ant-input-lg {
        font-size: 14px;
        height: 40px;
        padding-top: 9.6px;
        padding-bottom: 7.6px;
      }
      .ant-input-affix-wrapper {
        .ant-input,
        .ant-input-lg {
          padding-top: 0;
          padding-bottom: 0;
          height: 20px;
        }
      }
    }
  }
}
.caption {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #595b64;
  margin-top: 8px;
}
