.modalWrapper {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}
.formInner {
  padding: 24px;
}
.formHeader {
  display: flex;
  margin-bottom: 8px;
}
.participantInfo {
  flex: 1;
}
.footerButtons {
  padding: 16px 24px;
  box-shadow: 0px -1px 0px #e1e1e1;
  display: flex;
  justify-content: flex-end;
  > button {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 482px) {
  .formHeader {
    margin-bottom: 0;
    flex-direction: column;
    > * {
      margin-bottom: 24px;
    }
  }
}
