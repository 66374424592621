.fieldTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #545454;
  margin-bottom: 3px;
}
.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  color: #f5222d !important;
  font-size: 14px;
  line-height: 1.3;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.is-invalid {
  border-radius: 6px;
  :global {
    .ant-select-selector {
      border-color: #f5222d !important;
    }
    .material-icons {
      color: #f5222d !important;
    }
  }
}
:global {
  .ant-select-item {
    padding: 6px 12px;
  }
}
.bgLightGray {
  :global {
    .ant-select-single:not(.ant-select-customize-input),
    .ant-select-multiple {
      .ant-select-selector {
        background-color: #f8f8f8;
      }
    }
  }
}
.isLargeWithoutFontSize {
  :global {
    .ant-select-selection-search-input,
    .ant-select-lg {
      font-size: 14px;
    }
  }
}
.required {
  color: #f5222d;
  margin-left: 5px;
}
.itemRound {
  :global {
    .ant-select-multiple {
      .ant-select-selection-item {
        height: 24px;
        line-height: 22px;
        border-radius: 20px;
        .ant-select-selection-item-remove {
          border: 1px solid #86878d;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          padding-top: 1px;
          .ant-select-selection-item-content {
            margin-right: 5px;
          }
          .anticon.anticon-close {
            font-size: 9px;
          }
        }
      }
    }
  }
}
.isGhost {
  :global {
    .ant-select {
      .ant-select-selector {
        background: white !important;
        .ant-select-selection-item {
          color: #3047c6 !important;
        }
      }
      .ant-select-arrow {
        color: #3047c6 !important;
      }
      &.ant-select-lg {
        .ant-select-selector {
          .ant-select-selection-placeholder {
            line-height: 42px !important;
          }
        }
      }
    }
  }
}
.textMedium {
  :global {
    .ant-select {
      font-size: 14px;
    }
  }
}
.dropdownIsMultipleOptionCheckbox {
  :global {
    .ant-select-item-option-content {
      order: 2;
      margin-left: 10px;
    }
    .ant-select-item-option-state {
      .ant-checkbox + span {
        padding-right: 4px;
        padding-left: 4px;
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: #eceefe;
      font-weight: 500;
    }
    .ant-checkbox-inner {
      border-width: 2px;
    }
    .ant-checkbox-checked::after,
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-inner {
      border-radius: 2px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #3c59f8;
      border-color: #3c59f8;
    }
  }
}
.dropdownStandard {
  :global {
    .ant-select-item.ant-select-item-group {
      color: #545454;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.isCustomMultipleSelected {
  :global {
    .ant-select-selector {
      > span {
        display: none;
        &.ant-select-selection-search,
        &.ant-select-selection-placeholder {
          display: block;
        }
        &:first-child {
          display: block;
        }
      }
      .custom-selected-tag {
        padding: 0 7px;
        position: relative;
        top: 1px;
      }
      .ant-select-selection-search-input {
        margin-left: 2px !important;
      }
    }
  }
}
