.deletePopup {
  width: 480px !important;
  padding: 20px 40px !important;
  div:first-child {
    background: rgba(235, 87, 87, 0.1) !important;
    border-color: #eb5757 !important;
  }
  span {
    background: #eb5757 !important;
  }
}
