.wrapper {
  display: flex;
}
.inner {
  display: flex;
  padding: 7px 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  &.isStarting {
    border-color: #1616161a;
  }
  &.buttonAsIcon {
    > button {
      border: 0 !important;
      padding: 0 !important;
      box-shadow: none;
      > span {
        &:nth-child(1) {
          margin: 0;
          svg {
            width: 32px;
            height: 32px;
          }
          & + span {
            display: none;
          }
        }
      }
    }
  }
}
.hide {
  display: none !important;
}
@media screen and (max-width: 991px) {
  .inner {
    border-color: transparent !important;
    padding: 0;
  }
}
