.modalWrapper {
  :global {
    .ant-modal-close {
      display: none;
    }
  }
}
.footerButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  :global {
    .ant-btn {
      min-width: 100px;
      margin: 0 12px;
    }
  }
}
.cancelBtn {
  background-color: transparent !important;
  border-color: #f34d4d !important;
  color: #f34d4d !important;
  &:hover,
  &:focus,
  &:not([disabled]):not(.disabled):active {
    background-color: transparent !important;
    border-color: #f34d4d !important;
    color: #f34d4d !important;
  }
}
.contentInner {
  color: #161616;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    max-width: 275px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 380px) {
  .footerButtons {
    justify-content: space-between;
    :global {
      .ant-btn {
        margin: 0;
      }
    }
  }
}
