.titleRightContent {
  display: flex;
  > * {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
  :global {
    .ant-select-lg .ant-select-selector {
      height: 40px !important;
    }
  }
}
.payrollEntriesFilter {
  :global {
    .ant-select-selector {
      .ant-select-selection-item {
        line-height: 40px !important;
      }
    }
  }
}
.confirmModal {
  width: 448px !important;
  max-width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  :global {
    .ant-modal-close {
      display: block;
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-confirm-body {
      position: relative;
      > span {
        &:first-child {
          position: absolute;
          font-size: 48px;
          color: #ffbb32;
          left: 26px;
          top: 49px;
          bottom: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .ant-modal-confirm-title {
      padding: 13px 18px 14px 24px;
      box-shadow: 0px 1px 0px #e1e1e1;
    }
    .ant-modal-confirm-content {
      padding: 28px 24px 20px 88px;
      margin: 0 !important;
      font-size: 14px;
      line-height: 24px;
      color: #1e202b;
    }
    .ant-modal-confirm-btns {
      flex: 1;
      width: 100%;
      padding: 16px 24px;
      box-shadow: 0px -1px 0px #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0;
    }
  }
  &.isDelete {
    :global {
      .ant-modal-confirm-btns {
        .ant-btn-primary {
          border-color: #f22013 !important;
          background: #f22013 !important;
        }
      }
    }
  }
  &.isInfo {
    :global {
      .ant-modal-confirm-btns {
        .ant-btn {
          &:not(.ant-btn-primary) {
            visibility: hidden;
          }
          &.ant-btn-primary {
            background-color: #243699 !important;
            border-color: #243699 !important;
          }
        }
      }
    }
  }
}
.pageHeader {
  margin-bottom: 10px !important;
  > div {
    &:nth-child(1) {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.tabs {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 16px 0 16px;
  min-height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  &.tabsMarginBottom {
    margin-bottom: 20px;
    min-height: calc(100% - 88px);
  }
}
.tabHeader {
  display: flex;
  border-bottom: 1px solid #d1d1d1;
  position: relative;
  margin-bottom: 16px;
  font-weight: 500;
  &:after,
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 16px;
    bottom: -1px;
    border-top: 1px solid #d1d1d1;
    height: 1px;
  }
  &:before {
    left: -16px;
  }
  &:after {
    right: -16px;
  }
  > div {
    flex: 1;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    &.active,
    &:hover {
      border-bottom: 2px solid #3c59f8;
    }
    > span {
      line-height: 24px;
      display: inline-block;
    }
  }
}
.tabContentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  &.contentPaddingBottom {
    padding-bottom: 24px;
  }
}
