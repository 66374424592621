@import "variables";
@import "styles";

.l-date-field {
  > div {
    &:nth-child(1) {
      > div {
        &:nth-child(1) {
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  body {
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    > div {
      height: auto !important;
    }
  }
}

// Print
@media print {
  body {
    overflow: auto !important;
  }
  .ant-layout-sider {
    display: none !important;
  }
  .gx-main-content-wrapper {
    margin: 0 !important;
  }
}
