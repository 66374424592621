.header {
  padding: 0 16px 16px 24px;
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1e202b;
  }
}
.noTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1e202b;
  margin-bottom: 8px;
}
.noDes {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  color: #1e202b;
}
.noDate {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #86878d;
}
.noContentBox {
  position: relative;
  cursor: pointer;
}
.closeIcon {
  color: #86878d;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
}
.denyReason {
  margin-bottom: 8px;
}
.avatar {
  :global {
    .ant-scroll-number.ant-badge-dot {
      width: 12px;
      height: 12px;
      right: 5px;
      top: 5px;
    }
  }
}
.notifications {
  > li {
    &:last-child {
      padding-bottom: 0 !important;
    }
  }
}
.isRead {
  .noTitle {
    color: #86878d;
  }
  .noDes {
    color: #86878d;
  }
}
.notFound {
  min-width: 366px;
  min-height: 147px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 0 17px 0;
  flex-direction: column;
  > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #1e202b4d;
  }
}
@media screen and (max-width: 575px) {
  .notifications {
    > li {
      padding-right: 16px !important;
    }
  }
}
